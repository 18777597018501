import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { useAuth } from './hooks/useAuth';
import { ToastContainer } from 'react-toastify';
import graphqlClient from './lib/graphql/client';
import Spinner from './components/loadingIndicator/spinner';
import { BreakpointProvider } from 'react-socks';
import { BRAND, INFLUENCER } from './constants/roles';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';

const AuthenticatedLayout = React.lazy(() => import('./components/layouts/authenticated'));
const UnauthenticatedLayout = React.lazy(() => import('./components/layouts/unauthenticated'));

function App() {
  const {
    sessionToken,
    role,
    getSessionToken
  } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    if (sessionToken === undefined) {
      getSessionToken();
    }
  }, [sessionToken, getSessionToken])

  useEffect(() => {
    if (/\.sushivid\.com/.test(pathname)) {
      if (role === INFLUENCER) {
        window.location = 'https://influencers.sushivid.com';
      } else if (role === BRAND) {
        window.location = 'https://brands.sushivid.com';
      }
    }
  }, [pathname, role]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.fcWidget.init({
        token: '4a90e63b-c4d5-429a-a727-a24dffec294d',
        host: 'https://wchat.freshchat.com'
      });
    }
  }, []);

  let currentLayout = <Spinner />;

  if (sessionToken === null) {
    currentLayout = <UnauthenticatedLayout />;
  } else if (sessionToken) {
    currentLayout = <AuthenticatedLayout />;
  };

  return (
    <>
      <ApolloProvider client={graphqlClient(sessionToken)}>
        <BreakpointProvider>
          <Suspense fallback={<Spinner />}>
            {currentLayout}
          </Suspense>
        </BreakpointProvider>
      </ApolloProvider>
      <ToastContainer />
    </>
  );
}

export default App;
