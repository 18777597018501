import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, split } from "@apollo/client";
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from "@apollo/client/link/error";

export default function graphqlClient(token) {
  let httpLink;
  let wsLink;

  if (token) {
    httpLink = new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL,
      headers: { Authorization: `Bearer ${token}` }
    });

    wsLink = new WebSocketLink({
      uri: process.env.REACT_APP_GRAPHQL_SERVER_BASE_WS_URL,
      options: {
        reconnect: true,
        connectionParams: {
          headers: { Authorization: `Bearer ${token}` }
        }
      }
    });
  } else {
    httpLink = new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL
    });

    wsLink = new WebSocketLink({
      uri: process.env.REACT_APP_GRAPHQL_SERVER_BASE_WS_URL
    });
  }


  const splitLink = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message }) => {
        // console.log(
        //   `[GraphQL error]: Message: ${message}, Location: ${extensions.code}`
        // );
        if (message === "Could not verify JWT: JWTExpired" || message === "Malformed Authorization header") {
          if (token) {
            // history.push(
            //   "/influencer/update"
            // )
          } else {
            window.location = '/'
          }
        }
        return '';
      });
    if (networkError) {
      // console.log(`[Network error]: ${networkError}`);
      // props.history.push('/network-error') // redirect to network-error route
    }
  });

  const link = ApolloLink.from([
    errorLink,
    splitLink
  ]);

  const apolloClient = new ApolloClient({
    link,
    connectToDevTools: process.env.NODE_ENV === 'development',
    cache: new InMemoryCache({
      typePolicies: {
        influencers: {
          fields: {
            authorizations: {
              merge(_ignore, incoming) {
                return incoming;
              }
            }
          }
        }
      }
    })
  });

  return apolloClient;
};