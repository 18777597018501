import { appNames } from '../constants/appsSessionConfig';

const currentAppName = url => {
  switch (true) {
    case /go\.sushivid\.com/.test(url):
      return 'SushiVid';
    case /gosharelah\.com/.test(url):
      return 'GoShareLah';
    case /confirmpluschop\.com/.test(url):
      return 'ConfirmPlusChop';
    case /forum\.sushivid\.com/.test(url):
      return 'SushiVidForum';
    default:
      return 'SushiVid';
  }
}

const setUserDirection = () => {
  let returnTo = '/';
  let redirectUrl = undefined;

  try {
    redirectUrl = window.location.search.replace(/^\?/, '').split('&').filter(el => /return_to=/.test(el))[0].replace('return_to=', '');
    if (/sushivid\.com|gosharelah\.com|confirmpluschop\.com/.test(redirectUrl)) {
      returnTo = decodeURIComponent(redirectUrl);
    };
  } catch (err) { }

  const { appName } = getUserDirection();
  if (Object.values(appNames).includes(appName) && redirectUrl === undefined) return;

  const cookieValue = JSON.stringify({ appName: currentAppName(redirectUrl), returnTo });
  document.cookie = `fromApp=${cookieValue}; max-age=${21600}; samesite=strict; path=/`
}

const getUserDirection = () => {
  let fromAppObject = {};
  try {
    const savedCookies = document.cookie.split('; ').filter(cookie => /^fromApp/.test(cookie))[0].replace('fromApp=', '');
    fromAppObject = JSON.parse(savedCookies)
  } catch (err) { }

  return fromAppObject;
}

const clearUserDirection = () => {
  try {
    document.cookie = 'fromApp=; max-age=0'
    // document.cookie = document.cookie.split('; ').filter(element => !/^fromApp={"appName"/.test(element))
  } catch (err) { }
}

const getQueryParams = searchQuery => {
  let queryParams = {};
  if (searchQuery) {
    try {
      searchQuery.substring(1).split('&').forEach(pr => {
        const keyPair = pr.split('=');
        if (/^(?:social_media_link|code|step|encrypted_data)$/.test(keyPair[0])) {
          queryParams[keyPair[0]] = keyPair[1];
        } else if (/^(?:return_to)$/.test(keyPair[0])) {
          queryParams[keyPair[0]] = encodeURIComponent(decodeURIComponent(keyPair[1]));
        }
      })
    } catch (error) { }
  }

  return queryParams;
}

const getAuthWithReturnUrl = fromPath => {
  let returnUrl;

  if (/^\/(?:wallet|wallet\/withdrawal|wallet\/withdrawal\/\d+\/receipt|social_media)\/?$/.test(fromPath)) {
    returnUrl = `${process.env.REACT_APP_BASE_URL}/influencers/sign_in?return_to=${fromPath}`;
  }

  return returnUrl;
}

const getReturnToUrl = searchQuery => {
  let returnTo;

  try {
    const { return_to: encodedParams } = getQueryParams(searchQuery);
    const decodedParams = decodeURIComponent(encodedParams);

    if (/^https:\/\/sapotmou\.sushivid\.com/.test(decodedParams)) {
      returnTo = decodedParams;
    } else if (/^\/+/.test(decodedParams)) {
      const path = decodedParams.replace(/^\/+|\?.+/g, '');
      returnTo = `${process.env.REACT_APP_BASE_URL}/${path}`;
    }
  } catch (err) { }

  return returnTo;
}

export {
  setUserDirection,
  getUserDirection,
  clearUserDirection,
  getQueryParams,
  getReturnToUrl,
  getAuthWithReturnUrl
};